module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caf.js","short_name":"Caf.js","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","icon":"src/images/logosquare.svg","display":"standalone","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","disableBgImage":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-prismjs","options":{}}],"defaultLayouts":{"docs":"/home/antlai/git/caf_gatsbysite/src/components/layoutDocsMDX.js","default":"/home/antlai/git/caf_gatsbysite/src/components/layoutMDX.js"},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/antlai/git/caf_gatsbysite"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
