// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apis-js": () => import("./../../../src/pages/apis.js" /* webpackChunkName: "component---src-pages-apis-js" */),
  "component---src-pages-autonomous-mdx": () => import("./../../../src/pages/autonomous.mdx" /* webpackChunkName: "component---src-pages-autonomous-mdx" */),
  "component---src-pages-collaborative-mdx": () => import("./../../../src/pages/collaborative.mdx" /* webpackChunkName: "component---src-pages-collaborative-mdx" */),
  "component---src-pages-docs-documentation-mdx": () => import("./../../../src/pages/docs/documentation.mdx" /* webpackChunkName: "component---src-pages-docs-documentation-mdx" */),
  "component---src-pages-hosting-mdx": () => import("./../../../src/pages/hosting.mdx" /* webpackChunkName: "component---src-pages-hosting-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orchestration-mdx": () => import("./../../../src/pages/orchestration.mdx" /* webpackChunkName: "component---src-pages-orchestration-mdx" */),
  "component---src-pages-permanent-mdx": () => import("./../../../src/pages/permanent.mdx" /* webpackChunkName: "component---src-pages-permanent-mdx" */)
}

